import React, { useState, useMemo, useEffect } from "react";
import { LabelLarge, LabelMedium } from "baseui/typography";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { TextButton, KIND, SIZE } from "../../components/TextButton";
import DebtorRight from "../../assets/img/svg/DebtorRight";
import EmailSvg from "../../assets/img/svg/emailSvg";
import CallBackSvg from "../../assets/img/svg/CallBackSvg";
import { paymentAmount, setDebtorRightsModal } from "../../redux/Layout/action";
import {
  NavLink,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import _ from "lodash";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import { useQuery } from "react-query";
import {
  getCallBackLater,
  getInvoice,
  popUp,
  welcomeTextLayout,
} from "../../services/customerPortal";
import CustomerPortalModal from "../../components/PortalModal/PortalModal";
import { LoanBroker } from "../../constants/IndustrialSector";
import { getSelectedInvoice } from "../../redux/Invoice/action";

const CustomizeUi = () => {
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  let showActiveUser = addSearchParams.get("i");

  let currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );
  const { default_color } = useSelector((s) => s.layoutReduceData);
  let refetchData = useSelector((e) => e.layoutReduceData.refetchData);
  const { data: isWelcomeData, isLoading: isWelcomeLoading } = useQuery(
    ["WelcomeLayoutData", { refetchData }],
    async () => {
      return await welcomeTextLayout();
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const navigate = useNavigate();
  const [queryFilter, setQueryFilter] = useState({
    invoice_number: showActiveUser ? showActiveUser : "",
    type: [],
  });
  const [querySortBy, setQuerySortBy] = useState("invoice_due_date");

  const invoiceData = useQuery(
    [
      `INVOICES`,
      {
        filters: queryFilter,
        sort: querySortBy,
      },
    ],
    async ({ queryKey }) => {
      let { sort, filters } = queryKey[1];
      let is_internal = localStorage.getItem("is_internal_call");
      let is_internal_call;
      if (is_internal) {
        is_internal_call = is_internal;
      }

      return await getInvoice({
        sort,
        ...filters,
        ...(is_internal ? { is_internal_call } : {}),
      });
    },
    {
      retryOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (invoiceData?.data?.data?.docs) {
      let dataSelected = invoiceData?.data?.data?.docs.map((e) => {
        return {
          original: { ...e },
        };
      });
      dispatch(getSelectedInvoice(dataSelected));
    }
  }, [invoiceData.isFetched, invoiceData.isLoading]);

  console.log("invoiceData>>", invoiceData);

  const {
    organization,
    customer,
    referenceData,
    debtor_rights_modal,
    modalState,
    accessData,
  } = useSelector((s) => s.layoutReduceData);

  let accessValue = useSelector((e) => e.layoutReduceData.accessData);

  const totalAmount = parseFloat(customer?.total_outstanding_amount) || 0;

  let organizationData = useSelector((e) => e.layoutReduceData.organization);

  const dispatch = useDispatch();
  const location = useLocation();
  const hash = location?.pathname?.split("/")[1];

  const customizedData = useSelector((state) => state?.customizeReducer?.data);

  function sendEmail() {
    if (_.get(customizedData, "em_conf", "")) {
      window.location.assign(`mailto:${_.get(customizedData, "em_conf", "")}`);
    } else {
      window.location.assign(
        `mailto:${organizationData && organizationData.email}`
      );
    }
  }

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  const [count, setCount] = useState(0);
  const { data } = useQuery(
    [`PROMISE-TO-PAY-${count} `],
    async () => {
      return await getCallBackLater({ entity: "PROMISE_TO_PAY" });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const PromisePopUp = useQuery(
    "ModalPopUp",
    async () => {
      return await popUp();
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      isFetchedAfterMount: false,
      isFetched: false,
      refetchInactive: false,
    }
  );

  useEffect(() => {
    if (
      PromisePopUp.data?.data?.doc?.is_promise_pending &&
      modalState &&
      !debtor_rights_modal
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [PromisePopUp.data?.data?.doc?.is_promise_pending, debtor_rights_modal]);

  return (
    <>
      <div className="customize-ui-main-container">
        <div
          className={
            organization && organization.industry_sector === LoanBroker
              ? "customize-ui-container--modify"
              : "customize-ui-container"
          }
        >
          {/* {_.get(organizationData, "customer_portal_ui.logo") && (
            <div className="customize-icon-header">
              <img
                src={_.get(organizationData, "customer_portal_ui.logo", "")}
                // width={"114px"}
                // height={"111px"}

                style={{
                  minWidth: "124px",
                  maxWidth: "233px",
                  minHeight: "55px",
                  maxHeight: "90px",
                }}
              />
            </div>
          )} */}
          {_.get(organizationData, "customer_portal_ui.logo") && (
            <div className="customize-icon-header">
              <img
                src={_.get(organizationData, "customer_portal_ui.logo", "")}
                onLoad={(e) => {
                  const img = e.target;
                  const naturalWidth = img.naturalWidth;
                  const naturalHeight = img.naturalHeight;
                  console.log(naturalHeight <= 111, "sssfs");
                  // Set the size dynamically based on the image's natural size
                  img.style.width =
                    naturalWidth <= 233 ? `${naturalWidth}px` : "auto";
                  img.style.height =
                    naturalHeight <= 90 ? `${naturalHeight}px` : "auto";
                }}
                style={{
                  minWidth: "124px", // Min width to ensure some default size
                  maxWidth: "233px", // Max width
                  minHeight: "55px", // Min height to ensure some default size
                  maxHeight: "90px", // Max height
                }}
                alt="Organization Logo"
              />
            </div>
          )}

          <div
            className={
              _.get(organizationData, "customer_portal_ui.logo")
                ? "customize-welcome-text"
                : "customize-welcome-text--modify"
            }
          >
            <LabelMedium
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.2px",
              }}
            >
              {_.get(isWelcomeData, "data.doc.welt", "")}
            </LabelMedium>
          </div>
          <div className="customize-ui-amnt">
            <NumericFormat
              displayType="text"
              value={totalAmount}
              thousandsGroupStyle={currencyGroupStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={currencySeparator}
              decimalSeparator={currencyDecimalSeparator}
              renderText={(values) => (
                <>
                  <LabelLarge
                    type="h2"
                    style={{
                      fontSize: "30px",
                      fontWeight: "700",
                      lineHeight: "36px",
                    }}
                  >
                    {currencyList[customer?.currency]
                      ? currencyList[customer?.currency]
                      : customer?.currency}
                    {values}
                  </LabelLarge>
                </>
              )}
            />
          </div>
          <div className="customize-ui-btns">
            <div>
              <TextButton
                size={SIZE.compact}
                fullWidth
                onClick={() => {
                  dispatch(paymentAmount(totalAmount));
                  navigate(
                    `/${hash}/payments${
                      showActiveUser ? `?i=${showActiveUser}` : ""
                    }`
                  );
                }}
              >
                PAY NOW
              </TextButton>
            </div>
            <div>
              {accessValue?.is_request_payment_plan ||
              accessValue?.is_pre_defined_payment_plan ? (
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  fullWidth
                  style={{
                    border: "1px solid #CDCED9",
                  }}
                  onClick={() => {
                    navigate(
                      `/${hash}/requesting-times${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`
                    );
                  }}
                >
                  {data?.data?.doc?.is_payment_plan_pending
                    ? "VIEW INSTALLMENTS"
                    : data?.data?.doc?.is_promise_to_pay_pending
                    ? "VIEW PROMISE"
                    : data?.data?.doc?.is_offer
                    ? "VIEW OFFERS"
                    : "PAYMENT  OPTIONS"}
                </TextButton>
              ) : accessValue?.is_request_back ? (
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  fullWidth
                  style={{
                    border: "1px solid #CDCED9",
                  }}
                  onClick={() => {
                    navigate(
                      `/${hash}/requesting-times${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`
                    );
                  }}
                >
                  {data?.data?.doc?.is_payment_plan_pending
                    ? "VIEW INSTALLMENTS"
                    : data?.data?.doc?.is_promise_to_pay_pending
                    ? "VIEW PROMISE"
                    : "PAY LATER"}
                </TextButton>
              ) : (
                ""
              )}
            </div>

            <div>
              <TextButton
                size={SIZE.compact}
                kind={KIND.tertiary}
                fullWidth
                onClick={() => navigate(`/${hash}/invoices`)}
              >
                VIEW DETAILS
              </TextButton>
            </div>
          </div>
          <div className="cust-border-line">
            <div
              style={{
                width: "50%",
                border: "1px solid #EEEEEE",
              }}
            ></div>
            <div>
              <LabelMedium style={{ color: "#EEEEEE" }}>or</LabelMedium>
            </div>

            <div style={{ width: "50%", border: "1px solid #EEEEEE" }}></div>
          </div>

          <div className="summery-action-view-icons">
            <div className="action_wraper">
              <div
                className="icon-border"
                onClick={() => {
                  sendEmail();
                }}
              >
                <EmailSvg size={18} color={default_color} />
              </div>
              <LabelMedium
                type="p"
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "17px",
                }}
              >
                Email us
              </LabelMedium>
            </div>
            {accessData.is_callback && (
              <div className="action_wraper">
                <div
                  className="icon-border"
                  onClick={() =>
                    navigate(
                      `/${hash}/requesting-calls${
                        showActiveUser ? `?i=${showActiveUser}` : ""
                      }`
                    )
                  }
                >
                  <CallBackSvg size={18} color={default_color} />
                </div>
                <LabelMedium
                  style={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "17px",
                  }}
                >
                  Call-Back
                </LabelMedium>
              </div>
            )}

            {accessData.is_mini_miranda_configured && (
              <div className="action_wraper">
                <div
                  className="icon-border"
                  onClick={() => {
                    dispatch(setDebtorRightsModal(true));
                  }}
                >
                  <DebtorRight color={default_color} />
                </div>
                <LabelMedium
                  style={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "17px",
                  }}
                >
                  Consumer Rights
                </LabelMedium>
              </div>
            )}
          </div>
          <div className="compliance-info-section">
            <LabelMedium
              style={{
                color: "#787878",
                fontSize: "11px",
                lineHeight: "15px",
                fontWeight: "400",
              }}
            >
              {_.get(organizationData, "customer_portal_ui.comi", "")}
            </LabelMedium>
          </div>
        </div>
      </div>
      {PromisePopUp?.data?.data?.doc?.is_promise_pending &&
      isOpen &&
      modalState ? (
        <CustomerPortalModal
          PromisePopUp={PromisePopUp}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default CustomizeUi;
